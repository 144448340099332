import React from "react";
import "./App.css";

import { GoogleLogin } from "react-google-login";

import { gapi } from "gapi-script";

function App() {
  // const login = useGoogleLogin({
  //   onSuccess: (tokenResponse) => console.log(tokenResponse),
  //   onError: (err) => console.log(err),
  // });

  React.useEffect(() => {
    const initClient = () => {
      gapi.client.init({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        scope: "",
      });
    };
    gapi.load("client:auth2", initClient);
  });

  const onSuccess = (res: any) => {
    console.log("success:", res);
  };
  const onFailure = (err: any) => {
    console.log("failed:", err);
  };

  return (
    <div className="App">
      <button
        onClick={() => {
          console.log(gapi.auth.getToken().access_token);
        }}
      >
        Get tok
      </button>

      {/* <GoogleLogin
        onSuccess={(credentialResponse) => {
          console.log(credentialResponse);
        }}
        onError={() => {
          console.log("Login Failed");
        }}
        text="signup_with"
        width="500"
      /> */}

      {/* <GoogleButton onClick={() => login()} /> */}

      <GoogleLogin
        clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}
        buttonText="Sign in with Google"
        onSuccess={onSuccess}
        onFailure={onFailure}
        cookiePolicy={"single_host_origin"}
        isSignedIn={true}
      />
    </div>
  );
}

export default App;
